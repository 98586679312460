import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import styles from '../css/privacy.module.css';
import { graphql } from 'gatsby';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const privacy = ({ data }) => {
	const { titel, beschrijving: { json } } = data.privacy;

	return (
		<Layout>
			<Seo title='Privacy' />
			<div className={styles.privacy}>
				<div className={styles.center}>
					<h1>{titel}</h1>
					<article className={styles.post}>{documentToReactComponents(json)}</article>
				</div>
			</div>
		</Layout>
	);
};

export const query = graphql`
	query {
		privacy: contentfulPrivacyverklaring {
			titel
			beschrijving {
				json
			}
		}
	}
`;
export default privacy;
